
import React from 'react';
import { Button } from '@/components/ui/button';
import { Copy, Printer, CheckCircle, ListChecks, AlertCircle, Speech } from 'lucide-react';
import { toast } from '@/hooks/use-toast';
import { parseMarkdown } from '@/pages/labs/hooks/utils';
import { ConversationEntry } from '../../types';

interface AnalysisResultsProps {
  analysisResults: string;
  conversation: ConversationEntry[];
}

export const AnalysisResults: React.FC<AnalysisResultsProps> = ({ 
  analysisResults,
  conversation
}) => {
  const handleCopySummary = () => {
    if (analysisResults) {
      // Copy the original markdown text, not the HTML
      navigator.clipboard.writeText(analysisResults)
        .then(() => {
          toast({
            title: "Summary Copied",
            description: "The conversation summary has been copied to your clipboard.",
          });
        })
        .catch(err => {
          console.error("Failed to copy summary:", err);
          toast({
            title: "Copy Failed",
            description: "Failed to copy the summary. Please try again.",
            variant: "destructive",
          });
        });
    }
  };

  const handlePrintConversation = () => {
    if (!conversation.length || !analysisResults) return;

    const printWindow = window.open('', '_blank');
    if (!printWindow) {
      toast({
        title: "Print Failed",
        description: "Unable to open print window. Please check your popup settings.",
        variant: "destructive",
      });
      return;
    }

    const conversationHtml = conversation.map((entry, index) => `
      <div style="margin-bottom: 20px; padding: 12px; border: 1px solid #e2e8f0; border-radius: 8px;">
        <h3 style="margin-top: 0; color: #4338ca; font-weight: bold;">${entry.agent} (${entry.persona})</h3>
        <p style="margin-bottom: 0;">${entry.message.replace(/\n/g, '<br/>')}</p>
        <div style="font-size: 0.75rem; color: #64748b; margin-top: 8px;">Model: ${entry.model.split('/').pop()}</div>
      </div>
    `).join('');

    printWindow.document.write(`
      <!DOCTYPE html>
      <html>
      <head>
        <title>AI Agents Conversation Analysis</title>
        <meta charset="utf-8">
        <style>
          body { font-family: system-ui, -apple-system, sans-serif; line-height: 1.5; padding: 20px; max-width: 800px; margin: 0 auto; }
          h1 { color: #1e293b; margin-bottom: 30px; }
          h2 { color: #334155; margin-top: 30px; margin-bottom: 15px; }
          hr { border: 0; height: 1px; background: #e2e8f0; margin: 30px 0; }
          .analysis { white-space: pre-wrap; }
          pre { background-color: #f8fafc; padding: 15px; border-radius: 8px; overflow-x: auto; }
          code { font-family: monospace; }
          ul, ol { margin-left: 20px; }
          blockquote { border-left: 4px solid #e2e8f0; padding-left: 15px; margin-left: 0; font-style: italic; }
        </style>
      </head>
      <body>
        <h1>AI Agents Conversation Analysis</h1>
        
        <h2>Conversation Analysis</h2>
        <div class="analysis">${parseMarkdown(analysisResults)}</div>
        
        <hr>
        
        <h2>Full Conversation</h2>
        ${conversationHtml}
        
        <footer style="margin-top: 50px; font-size: 0.8rem; color: #94a3b8; text-align: center;">
          Generated by AI Agents Lab on ${new Date().toLocaleDateString()}
        </footer>
      </body>
      </html>
    `);

    printWindow.document.close();
    
    setTimeout(() => {
      printWindow.print();
      printWindow.close();
    }, 500);
  };

  return (
    <div className="space-y-6">
      <div>
        <div className="flex justify-between items-center mb-3">
          <h3 className="text-lg font-medium">Analysis Results</h3>
          <div className="flex gap-2">
            <Button variant="outline" size="sm" onClick={handleCopySummary} className="h-8">
              <Copy className="h-3.5 w-3.5 mr-1" />
              Copy
            </Button>
            <Button 
              onClick={handlePrintConversation} 
              variant="outline"
              size="sm"
              className="h-8"
            >
              <Printer className="h-3.5 w-3.5 mr-1" />
              Print
            </Button>
          </div>
        </div>
        <div 
          className="text-gray-700 bg-gray-50 p-5 rounded-md border border-gray-200 prose prose-sm max-w-none"
          dangerouslySetInnerHTML={{ __html: parseMarkdown(analysisResults) }}
        />
        <div className="flex flex-wrap gap-3 mt-4">
          <div className="flex items-center text-sm bg-purple-50 text-purple-700 px-3 py-1 rounded-full">
            <CheckCircle className="h-3.5 w-3.5 mr-1.5" />
            <span>Key Insights</span>
          </div>
          <div className="flex items-center text-sm bg-blue-50 text-blue-700 px-3 py-1 rounded-full">
            <ListChecks className="h-3.5 w-3.5 mr-1.5" />
            <span>Discussion Points</span>
          </div>
          <div className="flex items-center text-sm bg-amber-50 text-amber-700 px-3 py-1 rounded-full">
            <AlertCircle className="h-3.5 w-3.5 mr-1.5" />
            <span>Areas of Disagreement</span>
          </div>
          <div className="flex items-center text-sm bg-emerald-50 text-emerald-700 px-3 py-1 rounded-full">
            <Speech className="h-3.5 w-3.5 mr-1.5" />
            <span>Further Exploration</span>
          </div>
        </div>
      </div>
    </div>
  );
};
